/* Override base SVG style to work with Ant Design */
svg {
    vertical-align: initial;
}

.ant-table-row-expand-icon {
    float: none;
    margin: auto;
}

.ant-image-preview-img {
    margin: auto;
}

.ellipsis-container {
    overflow: hidden;
    white-space: nowrap;
}

.ellipsis-container > small, .ellipsis-container > p, .ellipsis-container > span, .ellipsis-container > strong {
    text-overflow: ellipsis;
}

.selected-card {
  border: 1px solid black;
  transform: scale(1.02);
}

.firs-step{
}

.profile, .logout, reload{
}


.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: #04d11f !important;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-color: #04d11f !important;
}

.w-52 {
  width: 13rem; /* 208px */
}

@media (max-width: 480px) {
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
}